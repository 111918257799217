.home img {
    display: inline;
}

.img-sheep, .img-woolf {
    width: 15%;
}
.img-game {
    width: 35%;
}
.img-shepherd {
    width: 45%;
}

.public-domain {
    width: 20%;
    min-width: 200px;
    margin: 1rem;
}