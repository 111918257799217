.title {
    display: inline;
    font-family: pixelemulator;
    text-transform: uppercase;
    font-size: 70px;
    color: transparent;
    background: linear-gradient(180deg, #b11d18 65%, #862227 65% 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-filter: drop-shadow(5px 5px 0 #000);
    filter: drop-shadow(5px 5px 0 #000000);

    @media (min-width:640px) {
        font-size: 80px;
    }

    img {
        position: absolute;
        top: 0;
        left: -2rem;
        -webkit-transform: rotate(-10deg);
        -moz-transform: rotate(-10deg);
        -ms-transform: rotate(-10deg);
        -o-transform: rotate(-10deg);
        transform: rotate(-10deg);

        @media (min-width:640px) {
            top: -0.42rem;
            left: -3.8rem;
        }
    }
}