@mixin bar() {
    border: 5px solid #000;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bar {
    @include bar();
    width: 100%;
    height: 4.20rem;

    @media(max-width: 639px),
    (min-width:1024px) and (max-width: 1279px) {
        width: 8rem;
        height: 21rem;
    }
}

.restBar {
    @include bar();
    width: 12rem;
    height: 36rem;
    margin-bottom: 1rem;
}

@mixin indicator() {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    bottom: auto;
    background: #B11D18;
}

.indicator {
    @include indicator();

    @media(max-width: 639px),
    (min-width:1024px) and (max-width: 1279px) {
        width: 100%;
        top: auto;
        bottom: 0;
    }
}

.restIndicator {
    @include indicator();
    width: 100%;
    top: auto;
    bottom: 0;
}

.gen {
    color: #000;
    font-size: 1.1rem;
    line-height: 1.1rem;
    position: relative;
    z-index: 1;
}

.gen0,
.born1,
.born2,
.born3,
.born4 {
    width: 20%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media(max-width: 639px),
    (min-width:1024px) and (max-width: 1279px) {
        width: 100%;
        height: 20%;
        top: auto;
    }

    div {
        font-size: 0.9rem;
    }
}

.gen0 {
    left: 0;
    bottom: auto;

    @media(max-width: 639px),
    (min-width:1024px) and (max-width: 1279px) {
        left: auto;
        bottom: 0;
    }
}

.born1,
.born2,
.born3,
.born4 {
    border-left: 3px solid #862227;
    border-bottom: 0;

    @media(max-width: 639px),
    (min-width:1024px) and (max-width: 1279px) {
        border-bottom: 3px solid #862227;
        border-left: 0;
    }
}

.born1 {
    left: 20%;
    bottom: auto;

    @media(max-width: 639px),
    (min-width:1024px) and (max-width: 1279px) {
        left: auto;
        bottom: 20%;
    }
}

.born2 {
    left: 40%;
    bottom: auto;

    @media(max-width: 639px),
    (min-width:1024px) and (max-width: 1279px) {
        left: auto;
        bottom: 40%;
    }
}

.born3 {
    left: 60%;
    bottom: auto;

    @media(max-width: 639px),
    (min-width:1024px) and (max-width: 1279px) {
        left: auto;
        bottom: 60%;
    }
}

.born4 {
    left: 80%;
    bottom: auto;

    @media(max-width: 639px),
    (min-width:1024px) and (max-width: 1279px) {
        left: auto;
        bottom: 80%;
    }
}

.rest0,
.rest1,
.rest2,
.rest3 {
    position: absolute;
    top: auto;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    div {
        font-size: 0.9rem;
    }
}

.rest0 {
    width: 100%;
    height: 68.5%;
    left: auto;
    bottom: 0;
}

.rest1,
.rest2,
.rest3 {
    border-bottom: 3px solid #862227;
    border-left: 0;
}

.rest1 {
    left: auto;
    bottom: 68.5%;
    width: 100%;
    height: 5.55%;
}

.rest2 {
    left: auto;
    bottom: calc(68.5% + 5.55%);
    width: 100%;
    height: 16.66%;
}

.rest3 {
    left: auto;
    bottom: calc(68.5% + 5.55% + 16.66%);
    width: 100%;
    height: 9.25%;
}

.mint {
    color: #000;
}

@media only screen and (max-width: 1024px) {
    .bar {
        .gen {
            font-size: 1.2rem;
            line-height: 1.2rem;
        }

        .gen0,
        .born1,
        .born2,
        .born3,
        .born4 {

            div {
                font-size: 0.9rem;
            }
        }

        .rest0,
        .rest1,
        .rest2,
        .rest3,
        .rest4,
        .rest5 {

            div {
                font-size: 0.9rem;
            }
        }
    }
}

@media only screen and (max-width: 576px) {
    .bar {
        .gen {
            font-size: 0.8rem;
            line-height: 0.8rem;
        }

        .gen0,
        .born1,
        .born2,
        .born3,
        .born4 {

            div {
                font-size: 0.6rem;
            }
        }

        .rest0,
        .rest1,
        .rest2,
        .rest3,
        .rest4,
        .rest5 {

            div {
                font-size: 0.6rem;
            }
        }
    }
}