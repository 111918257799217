@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: pixeloid;
  src: url(assets/fonts/PixeloidMono-1G8ae.ttf);
}

@font-face {
  font-family: gamepixies;
  src: url(assets/fonts/Gamepixies-8MO6n.ttf);
}

@font-face {
  font-family: pixelemulator;
  src: url(assets/fonts/PixelEmulator-xq08.ttf);
}

html,
body {
  height: 100%;
}

body,
table {
  font-size: 1rem;
}

@media(min-width:640px) {
  body,
  table {
    font-size: 1.5rem;
  }
}

body,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: gamepixies;
  color: rgb(177, 29, 24);
}

h1,
h2 {
  font-weight: bold;
  text-transform: uppercase;
}

a:hover {
  color: rgb(134, 34, 39);
}

#root,
img {
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
}

#root {
  min-height: 100%;
  width: 100%;
  background-image: url(assets/images/banner.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.frame {
  border-style: solid;
  border-image: url(assets/images/wood-frame.svg) 30 / 1 / 0 stretch;
  border-width: 30px;
  background-color: rgba(237, 227, 209, 0.9);
  position: relative;
  overflow: hidden;
  padding: 10px;
}

@media(min-width: 640px) {
  .frame {
    padding: 20px;
  }
}

.frame .mask {
  position: absolute;
  pointer-events: none;
  border: 3px solid red;
  width: 120%;
  height: 120%;
  top: -20px;
  left: -20px;
  opacity: 0.04;
  background-image: url(assets/images/wood-mask.svg);
  background-repeat: repeat;
  background-size: 400px 268px;
}

.frame img {
  max-width: 100%;
}

:root {
  --pixel-bg: rgba(237, 227, 209, 1);
  --pixel-border: #862227;
  --pixel-border-2: #B11D18;
  --pixel-border-3: var(--pixel-border);
  --pixel: .11em;
}

.btn-frame {
  min-width: 100px;
  text-align: center;
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.2rem 1.5rem;
  margin: 2rem 1rem;
  background: var(--pixel-bg);
  box-shadow:

    /* Inner Background Color */
    0 calc(var(--pixel) * -3) 0 calc(var(--pixel) * -1) var(--pixel-bg),
    0 calc(var(--pixel) * 3) 0 calc(var(--pixel) * -1) var(--pixel-bg),
    0 calc(var(--pixel) * -6) 0 calc(var(--pixel) * -2) var(--pixel-bg),
    0 calc(var(--pixel) * 6) 0 calc(var(--pixel) * -2) var(--pixel-bg),
    0 calc(var(--pixel) * -9) 0 calc(var(--pixel) * -4) var(--pixel-bg),
    0 calc(var(--pixel) * 9) 0 calc(var(--pixel) * -4) var(--pixel-bg),
    0 calc(var(--pixel) * -12) 0 calc(var(--pixel) * -6) var(--pixel-bg),
    0 calc(var(--pixel) * 12) 0 calc(var(--pixel) * -6) var(--pixel-bg),

    /* Pixel Border Layer 1 */
    calc(var(--pixel) * -1) 0 0 0 var(--pixel-border),
    var(--pixel) 0 0 0 var(--pixel-border),
    0 calc(var(--pixel) * -2) 0 0 var(--pixel-border),
    0 calc(var(--pixel) * 2) 0 0 var(--pixel-border),
    0 calc(var(--pixel) * -5) 0 calc(var(--pixel) * -1) var(--pixel-border),
    0 calc(var(--pixel) * 5) 0 calc(var(--pixel) * -1) var(--pixel-border),
    0 calc(var(--pixel) * -7) 0 calc(var(--pixel) * -2) var(--pixel-border),
    0 calc(var(--pixel) * 7) 0 calc(var(--pixel) * -2) var(--pixel-border),
    0 calc(var(--pixel) * -10) 0 calc(var(--pixel) * -4) var(--pixel-border),
    0 calc(var(--pixel) * 10) 0 calc(var(--pixel) * -4) var(--pixel-border),
    0 calc(var(--pixel) * -13) 0 calc(var(--pixel) * -6) var(--pixel-border),
    0 calc(var(--pixel) * 13) 0 calc(var(--pixel) * -6) var(--pixel-border),

    /* Pixel Border Layer 2 */
    calc(var(--pixel) * -2) 0 0 0 var(--pixel-border-2),
    calc(var(--pixel) * 2) 0 0 0 var(--pixel-border-2),
    0 calc(var(--pixel) * -1) 0 var(--pixel) var(--pixel-border-2),
    0 var(--pixel) 0 var(--pixel) var(--pixel-border-2),
    0 calc(var(--pixel) * -4) 0 0 var(--pixel-border-2),
    0 calc(var(--pixel) * 4) 0 0 var(--pixel-border-2),
    0 calc(var(--pixel) * -6) 0 calc(var(--pixel) * -1) var(--pixel-border-2),
    0 calc(var(--pixel) * 6) 0 calc(var(--pixel) * -1) var(--pixel-border-2),
    0 calc(var(--pixel) * -8) 0 calc(var(--pixel) * -2) var(--pixel-border-2),
    0 calc(var(--pixel) * 8) 0 calc(var(--pixel) * -2) var(--pixel-border-2),
    0 calc(var(--pixel) * -11) 0 calc(var(--pixel) * -4) var(--pixel-border-2),
    0 calc(var(--pixel) * 11) 0 calc(var(--pixel) * -4) var(--pixel-border-2),
    0 calc(var(--pixel) * -14) 0 calc(var(--pixel) * -6) var(--pixel-border-2),
    0 calc(var(--pixel) * 14) 0 calc(var(--pixel) * -6) var(--pixel-border-2),

    /* Border Layer 3: --pixel-border-3 */
    calc(var(--pixel) * -3) 0 0 0 var(--pixel-border-3),
    calc(var(--pixel) * 3) 0 0 0 var(--pixel-border-3),
    0 0 0 calc(var(--pixel) * 2) var(--pixel-border-3),
    0 calc(var(--pixel) * -3) 0 var(--pixel) var(--pixel-border-3),
    0 calc(var(--pixel) * 3) 0 var(--pixel) var(--pixel-border-3),
    0 calc(var(--pixel) * -5) 0 0 var(--pixel-border-3),
    0 calc(var(--pixel) * 5) 0 0 var(--pixel-border-3),
    0 calc(var(--pixel) * -7) 0 calc(var(--pixel) * -1) var(--pixel-border-3),
    0 calc(var(--pixel) * 7) 0 calc(var(--pixel) * -1) var(--pixel-border-3),
    0 calc(var(--pixel) * -9) 0 calc(var(--pixel) * -2) var(--pixel-border-3),
    0 calc(var(--pixel) * 9) 0 calc(var(--pixel) * -2) var(--pixel-border-3),
    0 calc(var(--pixel) * -12) 0 calc(var(--pixel) * -4) var(--pixel-border-3),
    0 calc(var(--pixel) * 12) 0 calc(var(--pixel) * -4) var(--pixel-border-3),
    0 calc(var(--pixel) * -15) 0 calc(var(--pixel) * -6) var(--pixel-border-3),
    0 calc(var(--pixel) * 15) 0 calc(var(--pixel) * -6) var(--pixel-border-3);
}

.btn-frame:hover {
  font-weight: bold;
  text-decoration: underline;
}

.btn-frame.btn-small {
  font-size: 1.1rem;
  padding: 0 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.footer-social {
  font-size: 60px;
  margin-left: 12px;
  margin-right: 12px;
}

.footer-social:hover {
  opacity: 0.7;
}

.footer-social.twitter {
  color: #1DA1F2;
}

.footer-social.discord {
  color: #7289DA;
}