.tokenlist {
    min-height: 1rem;
    width: 100%;
    background-position: bottom left;
    background-repeat: repeat-x;
    background-size: auto; 
    padding: 3rem 1rem;

    .list {
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        justify-content: center;
        gap: 2rem 1.5rem;
    }
}