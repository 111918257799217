.woolf {
    position: relative;
    cursor: pointer;
    padding-top: 16px;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.3));
    transition-timing-function: ease;
    transition-duration: 0.3s;
    transition-property: filter;

    .alpha {
        position: absolute;
        z-index: 1;
        left: 0;
        top: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        font-weight: bold;
        background: #fff;
        opacity: 0.9;
        padding: 0 0.12rem 0 0.2rem;
        line-height: 1rem;
        border-radius: 4px;
        transition-timing-function: ease;
        transition-duration: 0.3s;
        transition-property: color, background-color;
    }

    .alphaSelected {
        @extend .alpha;
        color: #fff;
        background-color: #B01D18;
        border-radius: 0 0 4px 0;
    }

    .id {
        position: absolute;
        top: -0.5rem;
        left: 0;
        width: 100%;
        text-align: center;
        font-size: 0.9rem;
        color: #000;
    }

    &:hover {
        filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.8));
    }
}

.woolfRest {
    @extend .woolf;

    width: 78px;
    height: 78px;

    @media(min-width:768px) {
        width: 96px;
        height: 96px;
    }

    @media(min-width:1024px) {
        width: 146px;
        height: 146px;
    }

    @media(min-width:1280px) {
        width: 75px;
        height: 75px;
    }

    @media(min-width:1536px) {
        width: 100px;
        height: 100px;
    }

    img {
        max-width: none;
        position: absolute;

        top: calc(-9.36px + 16px);
        left: -9.36px;

        @media(min-width:768px) {
            top: calc(-11.52px + 16px);
            left: -11.52px;
        }

        @media(min-width:1024px) {
            top: calc(-17.52px + 16px);
            left: -17.52px;
        }

        @media(min-width:1280px) {
            top: calc(-9px + 16px);
            left: -9px;
        }

        @media(min-width:1536px) {
            top: calc(-12px + 16px);
            left: -12px;
        }
    }

    .alphaRest {
        @extend .alpha;
        left: 3px;
        top: 19px;
        opacity: 1;

        @media(max-width:767px),
        (min-width: 1280px) and (max-width: 1535px) {
            left: 1px;
            top: 17px;
        }
    }

    .alphaSelectedRest {
        @extend .alphaRest;
        color: #fff;
        background-color: #B01D18;
        border-radius: 4px;
    }
}

.woolfRestOutline {
    position: absolute;
    z-index: 1;
    transition-timing-function: ease;
    transition-duration: 0.1s;
    transition-property: outline;

    width: 74.88px;
    height: 74.88px;
    top: 16px;
    left: 0;

    @media(min-width:768px) {
        width: 92.16px;
        height: 92.16px;
    }

    @media(min-width:1024px) {
        width: 140.16px;
        height: 140.16px;
    }

    @media(min-width:1280px) {
        width: 72px;
        height: 72px;
    }

    @media(min-width:1536px) {
        width: 96px;
        height: 96px;
    }
}

.woolfRestOutlineSelected {
    @extend .woolfRestOutline;

    outline: 6px solid #B01D18;
    border-radius: 4px;
}

.imgValueContainer {
    border-radius: 4px;
}

.imgValueContainerSelected {
    outline: 6px solid #B01D18;
    border-radius: 4px;
    transition-timing-function: ease;
    transition-duration: 0.1s;
    transition-property: outline;
}