
.mint {
    color: #000;
    text-align: center;

    .amount, .cost {
        color: #B11D18;
    }
    .cost {
        margin-left: 1rem;
    }
    .amount {
        font-size: 1.5em;
    }
    .cost {
        .value {
            font-size: 1em;
        }
    }
}